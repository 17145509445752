<template>
  <div>
    <!-- Survey/Poll List -->
    <Title title="Surveys" v-if="'survey'===type" />
    <Title title="Polls" v-if="'poll'===type" />
    
    <div v-if="!neopathioUser.anonymize">
      <IconCard
        v-if="'survey'===type"
        title="Complete surveys and polls to earn rewards points to towards you purchases"
      />

      <div v-if="surveyPollList[0]" class="neopathio-wrapper">
        <Card
          v-for="item in surveyPollList"
          :key="item.id"
          :amount="item.points"
          :title="item.title"
          @select="onSurveyPollSelect"
          :value="item"
        />
      </div>
      <div v-else class="neopathio-wrapper">
        There is no available {{ 'survey' === type ? 'surveys':'polls' }} for you yet.
      </div>
    </div>
    <!-- End Survey/Poll List -->

    <!-- Survey/Poll History -->
    <Title class="neopathio-history-title mt-4" title="History" />
    <div v-if="surveyPollHistoryList[0]" class="neopathio-wrapper">
      <div
        v-for="result in surveyPollHistoryList"
        :key="result.id"
        class="neopathio-survey-history"
      >
        <div class="neopathio-survey-history-item">
          <div class="neopathio-survey-history-content">
            <div class="neopathio-survey-history-title">
              <span class="neopathio-history-complition-text">
                {{ 'survey' === type ? 'Survey':'Poll' }} taken - {{result.survey.points}} pt.
              </span>
              <a class="neopathio-cta-link" @click="onSurveyResultSelect(result)">
                <b>See results</b>
              </a>
            </div>
            <div class="neopathio-complited-survey-title">{{
              result.survey.title
            }}</div>
          </div>
          <div class="neopathio-survey-history-value">
            {{ getShortDateFormat(result.updated_at) }}
          </div>
        </div>
      </div>
    </div>
    <div v-else class="neopathio-wrapper">No answers for {{ 'survey' === type ? 'surveys':'polls' }} yet.</div>
    <!-- End Survey/Poll History -->

    <Modal
      ref="tribiefySurveyPollModal"
      :title="modalTitle"
      :hideHeader="isSurveyPollCompleteMessage"
      :hideFooter="!isSurveyPollResult"
      @closeModal="reset"
    >
      <template #modal-body>
        <Survey
          v-if="isSurveyPoll"
          :surveyPollData="surveyPollData"
          @showSurveyCompleteMessage="onShowSurveyCompleteMessage"
          @survey-completed="onSurveyCompleted"
          class="neopathio-survey-welcome"
        />
        <SurveyResult v-if="isSurveyPollResult || ( isSurveyPoll && 0 !== Object.keys(surveyResult).length)" :surveyResult="surveyResult" :class="{ 'pt-4': isSurveyPoll }">
          <div
            v-if="isPromoteResult"
            class="neopathio-promote-survey-poll-result-message"
          >
            It looks like you’ve already taken this
            {{ surveyPollType }}.<br />Here are your responses”.
          </div>
        </SurveyResult>
      </template>
    </Modal>
  </div>
</template>

<script>
import { TYPE } from "@/helpers/constants/survey";
import surveyActions from "@/mixins/surveyActions.mixin";
import dateActions from "@/mixins/dateActions.mixin";

import Card from "../common/card";
import Title from "../common/title";
import IconCard from "../common/infoCard";
import Modal from "@/components/common/modal";
import Survey from "@/components/common/Survey";
import SurveyResult from "@/components/common/SurveyResult";
import * as moment from 'moment';

const ZPD_TYPE_TIME_BASED_RELEVANCE = 2;
const FREQUENCY_UNITS = {
  1: 'day',
  2: 'week',
  3: 'month',
  4: 'year'
}

export default {
  name: "Surveys",
  mixins: [surveyActions, dateActions],
  components: {
    Card,
    IconCard,
    Title,
    Modal,
    Survey,
    SurveyResult,
  },
  props: {
    type: { required: true },
  },
  data() {
    return {
      isSurveyPoll: false,
      isSurveyPollResult: false,
      isPromoteResult: false,
      modalTitle: "",
      surveyPollData: {},
      surveyResult: {},
      isSurveyPollCompleteMessage: false,
      surveyPollList: [],
      surveyPollHistoryList: [],
    };
  },
  computed: {
    surveyPollType() {
      return TYPE[
        `${this.surveyPollData.type || this.surveyResult.survey.type}`
      ].toLowerCase();
    },
    surveyCompletePointsStyle() {
      return this.surveyPollData.points.toString().length > 4
        ? { fontSize: "1em", top: "29%" }
        : {};
    },
  },
  methods: {
    async init() {
      await this.getSurveyPollList();
      if (this.type === "survey") {
        await this.getSurveyResultList();
      } else if (this.type === "poll") {
        await this.getPollResultList();
      }
      this.initSurveyPollList();
    },
    reset() {
      this.isSurveyPoll = false;
      this.isSurveyPollResult = false;
      this.modalTitle = "";
      this.surveyPollData = {};
      this.surveyResult = {};
      this.isSurveyPollCompleteMessage = false;

      clearInterval(this.__setSurveyBgIntervalId);
      clearInterval(this.__setSurveyModalIntervalId);
    },
    onSurveyPollSelect(payload) {
      this.isSurveyPoll = true;
      this.surveyPollData = payload;
      if (
        null != this.surveyPollData?.frequency_unit && 0 < this.surveyPollData?.frequency
        && ZPD_TYPE_TIME_BASED_RELEVANCE === this.surveyPollData?.zpd_type
      ) {
        this.updateSurveyPollTimeBasedRelevanceIntroductionText(this.surveyPollData);
      } else {
        this.$refs.tribiefySurveyPollModal.openModal();
      }

      this.initSurveyBackground(payload);

      this.__setSurveyModalIntervalId = setInterval(() => {
        let surveyNeopathioModalSelector = document.querySelector(".neopathio-modal")
        if (null == surveyNeopathioModalSelector) {
          return;
        }
        clearInterval(this.__setSurveyModalIntervalId);
      })
    },
    onSurveyResultSelect(payload, isPromoteResult) {
      this.isPromoteResult = isPromoteResult;
      this.isSurveyPollResult = true;
      this.surveyResult = payload;
      this.modalTitle = payload.survey.title;
      this.$refs.tribiefySurveyPollModal.openModal();
    },
    onShowSurveyCompleteMessage() {
      this.isSurveyPoll = false;
      this.isSurveyPollCompleteMessage = true;
    },
    async onSurveyCompleted(surveyResult) {
      surveyResult = {...surveyResult, ...{survey: this.surveyPollData, content: surveyResult.answers}};
      this.surveyResult = surveyResult;
      await this.init();
    },
    async onCloseModal() {
      this.$refs.tribiefySurveyPollModal.closeModal();
      await this.init();
    },
    initSurveyBackground(payload) {
      if (payload?.content?.backgroundImage) {
        this.__setSurveyBgIntervalId = setInterval(() => {
          let neopathioModalSelector = document.querySelector(".neopathio-modal");
          if (null == neopathioModalSelector) {
            return;
          }
          neopathioModalSelector.classList.add("survey-neopathio-modal");
          neopathioModalSelector.style.setProperty('--surveyBackgroundImage', `url("${payload.content?.backgroundImage}")`);
          clearInterval(this.__setSurveyBgIntervalId);
        });
      }
    },
    initSurveyPollList() {
      if ('survey' === this.type) {
        this.surveyPollList = this.surveyList;
        this.surveyPollHistoryList = this.surveyResultList;
      } else {
        this.surveyPollList = this.pollList;
        this.surveyPollHistoryList = this.pollResultList;
      }
    },
    getFrequencyText(frequencyUnit, duration) {
      let durationNumber;
      if ('day' == FREQUENCY_UNITS[frequencyUnit]) {
        durationNumber = duration.days();
      }
      if ('week' == FREQUENCY_UNITS[frequencyUnit]) {
        durationNumber = duration.asWeeks();
      }
      if ('month' == FREQUENCY_UNITS[frequencyUnit]) {
        durationNumber = duration.months();
      }
      if ('year' == FREQUENCY_UNITS[frequencyUnit]) {
        durationNumber = duration.years();
      }
      let retval = `${1 === durationNumber ? 'a' : durationNumber} ${FREQUENCY_UNITS[frequencyUnit]}`;
      if (durationNumber > 1) {
        retval = `${retval}s`;
      }
      return retval;
    },
    async updateSurveyPollTimeBasedRelevanceIntroductionText(surveyPollData) {
      try {
        let surveyPollResult = await this.getSurveyPollResultById(surveyPollData.id, surveyPollData.type);
        if (!surveyPollResult) {
          this.$refs.tribiefySurveyPollModal.openModal();
          return;
        }
        let duration = moment.duration(moment().diff(moment(surveyPollResult.created_at)));
        let frequencyUnit = surveyPollData.frequency_unit;
        let title = "Clothing interest update survey";
        let introductionText = "<p>It's been " + this.getFrequencyText(frequencyUnit, duration) +
          " since you've updated your clothing type interest. <br /> Let us know if anything has changed, and you'll receive " + surveyPollData.points + 
          " points for helping us out.</p>";
        
        if (!surveyPollData.content) {
          return surveyPollData;
        }
        
        surveyPollData.content.description = introductionText;
        surveyPollData.content.title = title;

        if (0 === surveyPollData.content.pages.length) {
          return surveyPollData;
        }

        if ("Introduction Page" === surveyPollData.content.pages[0].name) {
          surveyPollData.content.pages[0].elements[0].html = "";
        }
        this.$refs.tribiefySurveyPollModal.openModal();

      } catch (error) {
        console.log('Get survey/poll result error ', error)
        this.$refs.tribiefySurveyPollModal.openModal();
      }
    },
  },
  async mounted() {
    await this.init();
  },
};
</script>

<style scoped lang="scss">
.neopathio-survey-welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.neopathio-scroll-container {
  max-height: 200px;
  border-bottom: 2px solid #e5e5e5;
}
.neopathio-wrapper {
  margin-bottom: 1.5em;
}
.neopathio-card-title {
  font-size: 16px;
}
.neopathio-survey-history {
  display: flex;
  flex-direction: column;
  justify-content: left;
  flex-wrap: wrap;

  .neopathio-survey-history-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #e5e5e5;
    padding: 2px 0 12px 0;
    .neopathio-survey-history-content {
      margin-right: 4px;
      .neopathio-survey-history-title {
        margin: 0 16px 4px 0;
      }
      .neopathio-history-complition-text {
        font-weight: bold;
      }
      .neopathio-complited-survey-title {
        margin: 0 0 4px 0;
        font-size: 13px;
      }
    }
    .neopathio-survey-history-value {
      align-self: flex-start;
      text-align: right;
      white-space: nowrap;
    }
    &:last-child {
      border-bottom: none;
    }
  }
}
.tiribeify-survey-complete-notification {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  .tiribeify-survey-complete-icon {
    position: relative;
    width: 10em;
    .neopathio-survey-complete-points {
      position: absolute;
      top: 27%;
      left: 0;
      right: 0;
      overflow: hidden;
      text-align: center;
      font-size: 1.3em;
      color: var(--primary-color);
    }
    > svg {
      width: 100%;
      height: 100%;
    }
  }
  .neopathio-survey-complete-message {
    margin-bottom: 2rem;
    font-size: 21px;
    text-align: center;
    color: var(--primary-color);
  }

  .button-wrapper {
    margin-bottom: 2rem;
    .neopathio-button {
      background-color: var(--primary-color) !important;
    }
  }
}
.neopathio-promote-survey-poll-result-message {
  color: #31708f;
  background-color: #d9edf7;
  border: 1px solid #bce8f1;
  border-radius: 2px;
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 70%);
  text-align: center;
  margin-bottom: 1em;
  font-size: 1.5em;
}
.neopathio-info-card {
  margin-bottom: 24px;
}
.neopathio-history-title {
  padding-bottom: 12px;
  border-bottom: 1px solid #ccc;
}

.neopathio-cta-link {
  color: var(--primary-color);
}

@media only screen and (max-width: 640px) {
  .neopathio-survey-history {
    .neopathio-survey-history-title {
      width: 100%;
    }
  }
}
</style>

<style lang="scss">
.neopathio-modal-body {
  margin-bottom: 5rem;
  .sv-container-modern {
    .sv-completedpage {
      margin-left: 0 !important;
      margin-right: 0 !important;
      height: fit-content;
      padding-bottom: 0;
    }
  }
}
.survey-neopathio-modal {
  --surveyBackgroundImage: none;
  background-image: var(--surveyBackgroundImage);
  background-size: contain;

  .sv-body, .sv-page, .sv-completedpage {
    background-color: transparent !important;
  }
  .sv-completedpage {
    padding-top: 2.5em;
    &::before {
      display: none !important;
    }
  }
  .sv-body__footer {
    background: transparent;
  }
}
</style>
