<template>
  <div v-if="isModalOpen" class="neopathio-modal-overlay">
    <div class="neopathio-modal-locked">
      <div class="neopathio-modal">
        <div v-if="!hideHeader" class="neopathio-modal-header">
          <h3>{{ title }}</h3>
          <h5>{{ subTitle }}</h5>
          <button
            type="button"
            aria-label="Close"
            class="neopathio-modal-close"
            @click="closeModal"
          >
            ×
          </button>
        </div>
        <div class="neopathio-modal-body">
          <slot name="modal-body" />
        </div>
        <div v-if="!hideFooter" class="neopathio-modal-footer">
          <div class="neopathio-modal-buttons">
            <div class="neopathio-button-wrapper">
              <button
                class="neopathio-button neopathio--round"
                @click="closeModal"
              >
                Close
              </button>
            </div>
            <div v-if="isOkButton" class="neopathio-button-wrapper">
              <button class="neopathio-button neopathio--round">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    subTitle: String,
    hideHeader: Boolean,
    hideFooter: Boolean,
    isOkButton: Boolean,
    cancelOnly: Boolean,
  },
  data() {
    return {
      isModalOpen: false,
    };
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
      document.querySelector("body").classList.add("neopathio-modal-open");
    },
    closeModal() {
      this.isModalOpen = false;
      document.querySelector("body").classList.remove("neopathio-modal-open");
      this.$emit("closeModal");
    },
  },
};
</script>

<style lang="scss" scoped>
  .neopathio-modal-buttons {
    display: flex;
    justify-content: flex-end;

    .neopathio-button {
      background: var(--primary-color);
    }
  }
</style>