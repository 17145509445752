<template>
  <div class="right-to-forgotten-content">
    <div class="right-to-forgotten-text pe-md-0 pe-lg-5">
      <div v-if="showMessage" class="neopathio-anonymize-confirmation">Please check your email to confirm this action.</div>
      <RightToBeForgottenModal @closeModal="closeModal" ref="rightToBeForgottenModal" />
      <Title :title="'Exercise my Right to be Forgotten'" />
      <div class="neopathio-top-text">
        With the tap of a button, you can exercise your 'right to be forgotten' 
        from the {{shopName}} rewards program. *{{shopName}} will immediately anonymize all data collected and 
        remove any reference to your personal information and participation in the Program. 
        You will also receive email confirmation that your personal information and relationship to the 
        Program has been removed.
      </div>
      <div class="neopathio-bt-text">
        * Anonymized, personal level data is used expressly for the purpose of improving the rewards program 
        and overall customer experience when shopping at {{shopName}}.
      </div>
      <div class="neopathio-erase-data">
          <button class="neopathio-button neopathio-erase-data-cta" @click="showConfirmModal">Remove me</button>
      </div>
    </div>
    
    <div>
      <img :src="require('../../assets/images/people-03.png')" class="right-to-be-forgotten-img" />
    </div>
  </div>
</template>

<script>
  import Title from "../common/title";
  import RightToBeForgottenModal from "@/components/common/RightToBeForgottenModal";

  export default {
    name: "RightToBeForgotten",
    components: { Title, RightToBeForgottenModal },
    data() {
      return {
        showMessage: false,
        shopName: window.config.SHOP_NAME
      };
    },
    methods: {
      showConfirmModal() {
        this.$refs.rightToBeForgottenModal.openModal();
      },
      closeModal(status) {
        if (typeof status === 'boolean' && status) {
          this.showMessage = true;
        }
      }
    },
  };
</script>

<style scoped lang="scss">
  .right-to-forgotten-content {
    text-align: left;
    display: flex;
    flex-direction: row;

    .right-to-forgotten-text {
      .neopathio-top-text {
        margin-top: 20px;
        font-size: 15px;
      }
      .neopathio-bt-text {
        opacity: 0.8;
        font-size: 14px;
      }
    }

    .neopathio-erase-data {
      margin-top: 30px;
      width: 11%;
      min-width: 142px;

      .neopathio-button {
        background-color: var(--primary-color);
      }
    }

    .neopathio-anonymize-confirmation {
      padding: 20px;
      margin: 10px 0px;
      border-radius: 5px;
      background: #27ae60;
      color: #fff;
      font-size: 16px;
    }

    .right-to-be-forgotten-img {
      width: 126px;
      margin-top: 56px;
      margin-right: 60px;
      margin-left: 40px;
    }
  }
</style>
