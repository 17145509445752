import Vue from "vue";
import router from "./router"

window.onNeopathioWebScriptLoaded = function() {
  setTimeout(() => {
    initVueApp();
  },3000);
}

function initVueApp() {
  Vue.config.productionTip = false;
  new Vue({
    data: {
      currentRoute: window.location.pathname
    },
    computed: {
      ViewComponent () {
        return router[this.currentRoute]
      }
    },
    render (h) { return h(this.ViewComponent) }
  }).$mount("#neopathio-customer-experience");
}

