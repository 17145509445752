<template>
  <div class="neopathio-layout survey-public-page">
    <LandingPage />
    <Modal ref="tribiefyPublicSurveyPollModal" :title="modalTitle" :hideHeader="isSurveyCompleteMessage"
      :hideFooter="true" @closeModal="reset">
      <template #modal-body>
        <div>
          <Survey v-if="isSurvey" :surveyPollData="surveyPollData"
            @showSurveyCompleteMessage="onShowSurveyCompleteMessage" @survey-completed="onSurveyCompleted"
            class="neopathio-survey-welcome" />
        </div>
        <div class="survey-result-container" v-if="isSurveyResult">
          <SurveyResult :surveyResult="surveyResult">
            <div v-if="isPromoteResult" class="neopathio-promote-survey-poll-result-message">
              It looks like you’ve already taken this
              {{ surveyPollType }}.<br />Here are your responses”.
            </div>
          </SurveyResult>
        </div>

      </template>
    </Modal>
  </div>
</template>

<script>
import { TYPE } from "@/helpers/constants/survey";

import Modal from "@/components/common/modal";
import Survey from "@/components/common/Survey";
import SurveyResult from "@/components/common/SurveyResult";
import LandingPage from './landingPage';

import surveyActions from "@/mixins/surveyActions.mixin";

export default {
  name: "SurveyPublicPage",
  mixins: [
    surveyActions,
  ],
  components: {
    Modal,
    Survey,
    SurveyResult,
    LandingPage,
  },
  data() {
    return {
      isSurvey: false,
      isSurveyResult: false,
      isPromoteResult: false,
      modalTitle: "",
      surveyPollData: {},
      surveyResult: {},
      isSurveyCompleteMessage: false,
      survey: null
    };
  },
  computed: {
    surveyPollType() {
      return TYPE[
        `${this.surveyPollData.type || this.surveyResult.survey.type}`
      ].toLowerCase();
    },
    surveyCompletePointsStyle() {
      return this.surveyPollData.points.toString().length > 4
        ? { fontSize: "1em", top: "29%" }
        : {};
    },
    surveyId: function () {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get('survey');
    },
  },
  async mounted() {
    await this.init();
    await this.$getBrandAttributes();
    this.primaryColor = this.$brandAttributes.primaryColor;
    this.headingFont = this.$brandAttributes.headingFont;
    this.bodyCopyFont = this.$brandAttributes.bodyCopyFont;

    document.body?.style.setProperty('--primary-color', this.primaryColor);
    document.body?.style.setProperty('--body-font', this.bodyCopyFont);
    document.body?.style.setProperty('--header-font', this.bodyCopyFont);
    document.body?.style.setProperty('font-family', this.bodyCopyFont);
  },
  methods: {
    async init() {
      try {
        this.survey = await this.getSurveyGuestById(this.surveyId);
      } catch ({ response: { status } }) {
        console.log(status);
      }

      if (this.survey) {
        this.onSurveyPollSelect(this.survey);
      }
    },
    reset() {
      this.isSurvey = false;
      this.isSurveyResult = false;
      this.modalTitle = "";
      this.surveyPollData = {};
      this.surveyResult = {};
      this.isSurveyCompleteMessage = false;

      clearInterval(this.__setSurveyBgIntervalId);
      clearInterval(this.__setSurveyModalIntervalId);
    },
    onSurveyPollSelect(payload) {
      this.isSurvey = true;
      this.surveyPollData = payload;
      this.$refs.tribiefyPublicSurveyPollModal.openModal();
      this.initSurveyBackground(payload);

      this.__setSurveyModalIntervalId = setInterval(() => {
        let surveyNeopathioModalSelector = document.querySelector(".neopathio-modal")
        if (null == surveyNeopathioModalSelector) {
          return;
        }
        clearInterval(this.__setSurveyModalIntervalId);
      })
    },
    onShowSurveyCompleteMessage() {
      this.isSurvey = false;
      this.isSurveyCompleteMessage = true;
    },
    async onSurveyCompleted(surveyResult) {
      surveyResult = { ...surveyResult, ...{ survey: this.surveyPollData, content: surveyResult.answers } };
      this.surveyResult = surveyResult;
    },
    async onCloseModal() {
      this.$refs.tribiefyPublicSurveyPollModal.closeModal();
      await this.init();
    },
    initSurveyBackground(payload) {
      if (payload?.content?.backgroundImage) {
        this.__setSurveyBgIntervalId = setInterval(() => {
          let neopathioModalSelector = document.querySelector(".neopathio-modal");
          if (null == neopathioModalSelector) {
            return;
          }
          neopathioModalSelector.classList.add("survey-neopathio-modal");
          neopathioModalSelector.style.setProperty('--surveyBackgroundImage', `url("${payload.content?.backgroundImage}")`);
          clearInterval(this.__setSurveyBgIntervalId);
        });
      }
    },
  }
};
</script>

<style lang="scss">
.survey-public-page {
  .survey-neopathio-modal {
    top: 34% !important;
    --surveyBackgroundImage: none;
    background-image: var(--surveyBackgroundImage);
    background-size: contain;
  }

  .sv-container-modern,
  .sv-completedpage {
    color: var(--primary-color);
    font-family: var(--body-font);

    .sv-btn,
    .sv-item__control-label {
      font-family: var(--body-font);
    }

    .sv-title {
      font-family: var(--header-font);
    }
  }

  .sv-completedpage {
    padding-top: 2.5em;
    margin-left: 0 !important;
    margin-right: 0 !important;
    height: fit-content;
    padding-bottom: 0;
  }

  .sv-previous-button-label {
    svg {
      fill: #fff;
    }
  }

  .neopathio-button-wrapper {
    margin-bottom: 2rem;

    .neopathio-button {
      background-color: var(--primary-color) !important;
      color: #fff;
    }
  }

  .survey-result-container {
    padding-top: 150px;
  }
}
</style>