<template>
  <h2 class="neopathio-title">{{title}}</h2>
</template>

<script>
export default {
  name: 'Title',
  props: ['title'],
}
</script>

<style scoped lang="scss">
.neopathio-title {
  margin-bottom: 8px;
  text-transform: none;
}
</style>
